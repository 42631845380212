<template>
  <div class="accountManage">
    <div class="headerDetail">
      <ds-header title="订单管理/订单详情"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-descriptions title="订单信息" style="margin-bottom:50px">
      <a-descriptions-item label="订单编号">
        {{ form.orderNo }}
      </a-descriptions-item>
      <a-descriptions-item label="用户编号">
        {{ form.userId }}
      </a-descriptions-item>
      <a-descriptions-item label="手机号码">
        {{ form.mobile }}
      </a-descriptions-item>
      <a-descriptions-item label="自提地址" v-if="form.productType == 0">
        {{ `${form.pickAddressFirstLevel } - ${ form.pickAddressSecondLevel }` }}
      </a-descriptions-item>
      <a-descriptions-item label="下单时间">
        {{ form.gmtCreate ? moment(form.gmtCreate).format('YYYY-MM-DD HH:mm:ss') : '-' }}
      </a-descriptions-item>
      <a-descriptions-item label="核销有效期" v-if="form.productType == 0">
        {{ form.writeOffStartTime }} - {{ form.writeOffEndTime }}
      </a-descriptions-item>
      <a-descriptions-item label="兑换方式">
        {{ `${form.payType === '0' ? '仅积分' : '组合支付（积分+现金）'}` }}
      </a-descriptions-item>
      <a-descriptions-item label="商品类型">
        {{ `${ form.productType === 0 ? '实物商品' : form.productType === 1 ? '羊毛券' : '第三方串码' }` }}
      </a-descriptions-item>
    </a-descriptions>
    <div style="margin-bottom:50px">
      <div class="shopMessage">商品信息</div>
      <div class="shopDetail">
        <div class="blank">
          <img class="imgClass" :src="form.productImg">
        </div>
        <div class="topContent">
          <div>
            <span>商品名称：</span>
            <span>{{ form.productName }}</span>
          </div>
          <div v-if="form.productType === 0 && form.specType === '1'">
            <span>规格：</span>
            <span>{{ form.specDesc ? form.specDesc : '-' }}</span>
          </div>
          <div>
            <span>数量：</span>
            <span>{{ form.productSum }}</span>
          </div>
          <div>
            <span>单价：</span>
            <span>{{ form.productPrice ? form.productPrice.toFixed(2) : 0 }}</span>
          </div>
          <div>
            <span>订单金额：</span>
            <span>¥{{ `${form.cashFee ? form.cashFee : '0.00'}` }}</span>
          </div>
          <div>
            <span>实付金额：</span>
            <span>¥{{ `${form.cashFee ? form.cashFee : '0.00'}` }}</span>
          </div>
          <div>
            <span>积分消耗：</span>
            <span>{{ form.integralFee }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <span class="shopMessage">{{form.productType == 1 ? '发放记录' : form.productType === 0 ? '物流信息' : '串码详情'}}</span>
        <span style="float:right;color: #1890ff">{{ getOrderStatus(form.orderStatus) }}</span>
      </div>
      <div v-if="form.productType == 1">
        <span>
          发放成功:{{form.issueSuccessNum}}/ 发放失败:{{form.issueFailNum}}
        </span>
      </div>
      <div  v-if="form.productType == 0">
        <span>
          本商品无物流信息
        </span>
      </div>
      <div  v-if="form.productType == 2">
        <a-descriptions  style="margin-bottom:50px">
          <a-descriptions-item label="兑换串码">
            {{ form.code }}
          </a-descriptions-item>
          <a-descriptions-item label="串码核销有效期">
            {{ form.writeOffStartTime }} - {{ form.writeOffEndTime }}
          </a-descriptions-item>
          <a-descriptions-item label="串码状态">

            {{ form.codeStatus === 1 ? '未使用' : form.codeStatus === 2 ? '已使用' : form.codeStatus === 3 ? '已过期' : '-'}}
          </a-descriptions-item>
          <a-descriptions-item label="使用地点">
            {{ form.useLocation ? form.useLocation : '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="串码使用时间" v-if="form.codeStatus === 2">
            {{ form.useTime ? moment(form.useTime).format('YYYY-MM-DD HH:mm:ss') : '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="操作人" v-if="form.codeStatus === 2">
            {{ form.operateUser ? form.operateUser : '-' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>
    <a-descriptions title="订单支付信息" style="margin-bottom:50px" v-show="form.payType === '1'">
      <a-descriptions-item label="支付订单编号">
        {{ form.outId ? form.outId : '-' }}
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="订单返还信息" style="margin-bottom:50px" v-if="(form.orderStatus === 1 && form.payType !== '0') || form.orderStatus === 2 || form.orderStatus === 3">
      <a-descriptions-item label="返还类型">
        {{ `${form.refundOperateType === 0 ? '用户主动申请退款' : form.refundOperateType === 1 ? '商家返还' : form.refundOperateType === 2 ? '到期自动退' : '-'}` }}
      </a-descriptions-item>
      <a-descriptions-item label="返还方式">
        原路返回
      </a-descriptions-item>
      <a-descriptions-item label="操作人" v-if="form.refundOperateType === 1">
        {{ form.operateUser ? form.operateUser : '-' }}
      </a-descriptions-item>
      <a-descriptions-item label="操作时间">
        {{ `${form.refundTime ? moment(form.refundTime).format('YYYY-MM-DD HH:mm:ss') : '-' }` }}
      </a-descriptions-item>
      <a-descriptions-item label="返还金额" v-if="form.refundIntegral || form.refundCash">
        <span v-show="form.refundType === 0"> {{ `${form.refundIntegral}积分` }} </span>
        <span v-show="form.refundType === 1">{{ `￥${form.refundCash}` }}</span>
        <span v-show="form.refundType === 2">{{ `${form.refundIntegral}积分+￥${form.refundCash}` }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="退款订单编号" v-if="form.refundOrderId">
        {{ form.refundOrderId }}
      </a-descriptions-item>
      <a-descriptions-item label="返还时间" v-if="form.refundTime">
        {{ `${form.refundTime ? moment(form.refundTime).format('YYYY-MM-DD HH:mm:ss') : '-'}` }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import moment from 'moment'
import { orderDetal } from '@/api/orderList'
export default {
  name: "AccountManage",
  computed: {
  },
  data() {
    return {
      moment,
      id:'',
      form:{},
      state: [
        {
          label: '待核销',
          value: 0
        },
        {
          label: "已过期",
          value: 1,
        },
        {
          label: "已关闭",
          value: 2,
        },
        {
          label: "已售后",
          value: 3,
        },
        {
          label: "已完成",
          value: 4,
        }
      ],
    }
  },
  created() {
    this.id = this.$route.query.id
    console.log(this.$route.query.id)
    this.getOrderDetal()
  },
  methods: {
    // 订单状态
    getOrderStatus(status) {
      const statusObj = this.state.find(item => item.value === status)
      return statusObj ? statusObj.label : ''
    },


    async getOrderDetal(){
      const res = await orderDetal(this.id)
      this.form = {
        ...res.data
      }
      const { data } = res
      let specDescString = ''
      if(data.specDesc && data.specDesc !== "{}") {
        const specDesc = JSON.parse(data.specDesc)
        const keys = Object.keys(specDesc)
        specDescString = keys.map((item, index) => {
          return `${specDesc[keys[index]]}`
        }).join('；')
      }
      this.form.specDesc = specDescString
      this.form.payAt = this.form.payAt ? moment(this.form.payAt).format('YYYY-MM-DD HH:mm:ss') : '-'
      if(this.form.writeOffStartTime) {
        this.form.writeOffStartTime = moment(this.form.writeOffStartTime).format('YYYY-MM-DD HH:mm:ss')
      }
      if(this.form.writeOffEndTime) {
        this.form.writeOffEndTime = moment(this.form.writeOffEndTime).format('YYYY-MM-DD HH:mm:ss')
      }
      console.log(this.form,'000')
    }
  }
};
</script>

<style lang="scss" scoped>
.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.shopMessage {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.shopDetail {
  display: flex;
}
.blank .imgClass{
  width:150px;
  height:150px;
  display:inline-block
}
.topContent {
  height:150px;
  display:inline-block;
  margin-left:15px
}
</style>
