import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"
import store from "@/store";

const prefix = '/api/dscloud-member-mall'

// 获取订单列表
export const getOrderList = (params) => {
  return fetchApi(URL.ORDER_LIST, params, 'get', 'params', true);
}

// 关闭订单
export const orderClose = (id) => {
  const params = {
    orderId: id
  }
  return fetchApi(prefix + `/web/v2/order/close?orderId=${id}`, {}, 'PUT', 'params')
}

// 查看详情
export const orderDetal = (id) => {
  return fetchApi(prefix + `/web/v2/order/detail/${id}`)
}

// 导出
export const orderExport = (data) => {
  const currentRoleId = store.state.common.currentMenuRoleId
  const params = {
    ...data,
    roleId: currentRoleId
  }
  return http({
    url: URL.ORDER_EXPORT,
    responseType: 'blob',
    method: 'POST',
    data: params
  })
}

// 商品类型列表
export const getProductList = (id) => {
  return fetchApi(URL.GET_PRODUCT_LIST + id)
}

// 获取项目列表
export const getProjectList = () => {
  return fetchApi(URL.GET_PROJECT_LIST)
}

/**
  * @description: 第三方串码确认使用
  * @author: cxd
  * @time: 2024/6/11
 **/
export const confirmOrderUsed = (orderId) => {
  return fetchApi(prefix + `/web/v2/order/useNow/${orderId}`, {}, 'POST', 'params')
}

/**
  * @description: 售后处理
  * @author: cxd
  * @time: 2024/6/12
 **/
export const handleAfterSales = (params) => {
  return fetchApi(prefix + `/web/v2/order/aftersales?orderId=${params.orderId}&type=${params.type}`, {}, 'PUT', 'params')
}


/**
  * @description: 取消订单
  * @author: cxd
  * @time: 2024/6/12
 **/
export const handleCancelOrder =(params) =>{
  return fetchApi(prefix + `/web/v2/order/close?orderId=${params.orderId}&type=${params.type}`, {}, 'PUT', 'params')
}
